import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loading$: Observable<boolean>;

  private loadingCount = 0;
  private loadingSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.loading$ = this.loadingSubject.asObservable();
  }

  loadingOn(): void {
    this.loadingCount++;
    this.updateLoadingState();
  }

  loadingOff(): void {
    if (this.loadingCount > 0) {
      this.loadingCount--;
    }
    this.updateLoadingState();
  }

  reset(): void {
    this.loadingCount = 0;
    this.updateLoadingState();
  }

  private updateLoadingState(): void {
    this.loadingSubject.next(this.loadingCount > 0);
  }
}

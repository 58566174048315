import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';

import { LoadingService } from './loading.service';

export const SkipLoading = new HttpContextToken<boolean>(() => false);

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check for a custom attribute to avoid showing the loading spinner
    if (req.context.get(SkipLoading)) {
      return next.handle(req);
    }

    this.loadingService.loadingOn();

    return next.handle(req).pipe(
      finalize(() => {
        this.loadingService.loadingOff();
      }),
    );
  }
}

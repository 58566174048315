import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { LoadingService } from './loading.service';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  imports: [AsyncPipe],
  standalone: true,
})
export class LoadingIndicatorComponent implements OnInit {
  @Input() detectRouteTransitions = false;

  loading$: Observable<boolean>;

  private destroyRef = inject(DestroyRef);

  constructor(
    private loadingService: LoadingService,
    private router: Router,
  ) {
    this.loading$ = this.loadingService.loading$;
  }

  ngOnInit(): void {
    if (this.detectRouteTransitions) {
      this.router.events
        .pipe(
          tap((event) => {
            if (event instanceof NavigationStart) {
              this.loadingService.loadingOn();
            } else if (event instanceof NavigationEnd) {
              this.loadingService.loadingOff();
            } else if (event instanceof NavigationCancel) {
              this.loadingService.loadingOff();
            } else if (event instanceof NavigationError) {
              this.loadingService.loadingOff();
            }
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe();
    }
  }
}
